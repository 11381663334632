import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ZZZCombat: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Combat system</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_combat.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Combat system</h1>
          <h2>
            The combat system and its elements in Zenless Zone Zero explained.
          </h2>
          <p>
            Last updated: <strong>08/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Combat System video" />
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="vD2hbBykyVM" />
          </Col>
        </Row>
        <SectionHeader title="Combat System" />
        <StaticImage
          src="../../../images/zzz/guides/characters.webp"
          alt="Guide"
        />
        <p>
          Before battle you must assemble a team of up to 3 Agents. You can only
          control one Agent at a time but you can freely swap between them to
          access each of their abilities and combine them.
        </p>
        <SectionHeader title="Combat UI Elements" />
        <StaticImage src="../../../images/zzz/guides/intro_4.jpg" alt="Guide" />
        <h6>HP</h6>
        <ul>
          <li>
            When all your Agents HP is depleted you will be evacuated from the
            current combat zone or hollow.
          </li>
        </ul>
        <StaticImage src="../../../images/zzz/guides/intro_5.jpg" alt="Guide" />
        <h6>Energy</h6>
        <ul>
          <li>
            Agents gain energy over time when they are on the field based on
            their Energy Regen stat.
          </li>
          <li>
            Basic attacks, dodge counters, and dash attacks also generate energy
            for agents.
          </li>
          <li>
            Breaking supply chests and picking up energy matter dropped by
            defeated enemies increases energy for the entire squad.
          </li>
          <li>
            Each Agent has their own energy bar, cycling between each of them to
            ensure you make use of each of their energy is advised.
          </li>
        </ul>
        <StaticImage src="../../../images/zzz/guides/intro_6.jpg" alt="Guide" />
        <h6>Daze</h6>
        <ul>
          <li>
            When enemies are hit, they accumulate Daze. When Daze reaches 100%,
            enemies become stunned, rendering them unable to move and more
            susceptible to damage.
          </li>
          <li>
            While enemies are dazed they can be inflicted with your team's
            powerful chain attacks you’ll learn about below.
          </li>
        </ul>
        <SectionHeader title="Combat Actions" />
        <h5 className="with-icon">
          Basic Attack
          <StaticImage
            src="../../../images/zzz/icons/icon_basic.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>
            Press, tap and hold the appropriate key or button to launch a basic
            attack consisting of a number of strikes (usually 3-5). Some agents
            unleash alternative moves by holding the basic attack during a basic
            attack combo.
          </li>
          <li>
            The Basic attack is your primary means of consistent damage on most
            characters and executing full basic attack chains will yield the
            most energy recharge and damage in most cases.
          </li>
        </ul>
        <h5 className="with-icon">
          Special Attack
          <StaticImage
            src="../../../images/zzz/icons/icon_special.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>
            Special attacks are normally the defining feature of an Agent and
            can be used at any time without any cooldown but can be used in a
            special enhanced state (EX Skill Variant) after accumulating enough
            energy. Special attacks have a heavy attack effect which when used
            on a fully dazed enemy will allow for a chain attack.
          </li>
          <li>
            Normal special attacks are very rarely worth using, they do low
            damage and do not regenerate energy and can mostly be ignored. The
            empowered EX Special attack is always worth using and should be
            prioritized as soon as it’s available but can be saved in
            preparation for stronger enemies or for specific character
            synergies.
          </li>
        </ul>
        <StaticImage src="../../../images/zzz/guides/intro_7.jpg" alt="Guide" />
        <h5 className="with-icon">
          Chain Attack
          <StaticImage
            src="../../../images/zzz/icons/icon_qte.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>
            If there are two or more Agents in your squad, hitting a stunned
            enemy after their daze reaches 100% with a heavy attack will trigger
            a chain attack.
          </li>
          <li>
            Pressing an Agent’s avatar on the left or right before the countdown
            at the bottom of the screen ends will trigger the Agent’s Chain
            Attack.
          </li>
          <li>
            These attacks do tremendous damage and can be used in quick
            succession while an enemy is stunned combining them is advised.
          </li>
        </ul>
        <StaticImage src="../../../images/zzz/guides/intro_8.jpg" alt="Guide" />
        <h5 className="with-icon">
          Ultimate
          <StaticImage
            src="../../../images/zzz/icons/icon_ulti_full.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>
            Hitting enemies, using chain attacks, dodge Counters, and triggering
            Assist Follow-Ups builds up Decibel Rating gauge.
          </li>
          <li>
            When your Decibel Rating reaches <strong>3,000</strong>, the
            Ultimate button will light up, signaling you may press the Ultimate
            key to clear the decibel rating and unleash an ultimate.
          </li>
          <li>
            You can check how close to the Ultimate using the tracker that has 3
            stages, once it says “Maximum” it’s ready:
          </li>
        </ul>
        <StaticImage src="../../../images/zzz/guides/intro_9.jpg" alt="Guide" />
        <p>
          Your entire team seems to share the{' '}
          <strong>Decibel Rating Gauge</strong> so be sure to use the ultimate
          on the character that will have the most impact in combat!
        </p>
        <h5 className="with-icon">
          Dodge
          <StaticImage
            src="../../../images/zzz/icons/icon_dodge.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>Press the appropriate key or button to Dodge.</li>
          <li>Using a Basic Attack while dodging will launch a Dash Attack.</li>
          <li>
            Dodging when your Agent is about to be hit can trigger a “Perfect
            Dodge”.
          </li>
          <li>
            Quickly using a Basic Attack right after a Perfect Dodge will launch
            a Dodge Counter with a Heavy Attack effect.
          </li>
        </ul>
        <h5 className="with-icon">
          Switching Agents
          <StaticImage
            src="../../../images/zzz/icons/icon_assist.png"
            alt="Guide"
          />
        </h5>
        <ul>
          <li>
            When there are 2 or more Agents in the squad, pressing the agent
            switch key will replace the agent on the field with the next Agent
            to its right on the Agent bar in the top-left corner.
          </li>
        </ul>
        <h5>Assist Attack</h5>
        <ul>
          <li>
            When the Agent on the field is knocked back, another Agent’s Assist
            Attack window will trigger. Switch Agents during this window trigger
            a reactive assist.
          </li>
          <li>
            Reactive Assists have a Heavy Attack effect and can quickly turn the
            tides of battle back in your favour.
          </li>
        </ul>
        <StaticImage
          src="../../../images/zzz/guides/intro_10.jpg"
          alt="Guide"
        />
        <h5>Special: Perfect Assist</h5>
        <ul>
          <li>
            If you switch Agents right before some enemy attacks (those that
            flash gold) hit, the Agent switching in will consume an Assist Point
            to active a Defensive Assist or Evasive Assist.
          </li>
          <li>
            You can activate up to 3 Perfect Assists in a row within a certain
            period.
          </li>
          <li>You can trigger Chain Attacks to regenerate Assist Points.</li>
          <li>
            When you do not have Assist Points, the Agent Switching in will
            automatically Perfect Dodge instead.
          </li>
          <li>
            Pressing the Basic Attack or Special Attack button when a Perfect
            Assist is triggered will launch an Assist Follow-Up.
          </li>
        </ul>
        <p>
          To help you better understand the Dodge and Parry mechanics, you
          should watch this great video:
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="pVO_hC0nWbw" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZCombat;

export const Head: React.FC = () => (
  <Seo
    title="Combat System | Zenless Zone Zero | Prydwen Institute"
    description="The combat system and its elements in Zenless Zone Zero explained."
    game="zzz"
  />
);
